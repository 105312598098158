.container {
    margin-top: 1rem;
    padding: 20px 20px 20px 20px;
    border: .3px solid silver;
    box-shadow: 1px 1px 4px rgba(101, 87, 87, 0.26);
    border-radius: 10px;
}

.title {
    font-size: 18px;
    font-weight: 800;
    color: #1b1b1b;
    font-family: FiraSans-Bold!important;
}

.status {
    background: #AD0039!important;
    color: white;
    font-size: 14px;
    font-family: FiraSans-Light!important;
    padding: 7px 10px 7px 10px;
}

.complexityLevel {
    font-size: 14px;
    font-family: FiraSans-Light!important;
    padding: 7px 10px 7px 10px;    
}

.next {
    background: transparent;
    color: #AD0039;
    border: 0px solid transparent;
    cursor: pointer;
    font-size: 14px;
}

.next:hover {
    font-weight: bold;
}

.navIcon {
    color: silver;
    font-size: 14px;
    cursor: pointer;
}

.active {
    color: #AD0039;
    transform: scale(1.5);
    transition: transform 1s ease-in-out;
}

.input {
    width: 100%;
    font-size: 14px;
    font-weight: 200;
    background: transparent;
    border: 0px solid transparent;
    color: var(--input-color);
    font-family: FiraSans-Light!important;
}

.table {
    width: 100%;
}

.table thead th {
    font-size: 12px;
    font-family: FiraSans-Regular!important;
    font-weight: 500!important;
    text-align: left;
}

.table tbody tr td {
    font-size: 12px;
    font-family: FiraSans-Light!important;
    text-align: left;
}

.iconBtn {
    font-size: 12px;
    text-align: center;
    border: 0px solid transparent;
    background: transparent;
    cursor: pointer;
    margin: auto;
}

.label {
    text-align: left;
    font-size: 12px;
    color: var(--label-title-color);
    font-family: FiraSans-Regular!important;
}

.groupLabel {
    font-size: 16px; 
    margin-bottom: .5rem;
    color: var(--label-group-title-color);
    font-family: FiraSans-Medium!important;
}

.group {
    margin-bottom: 1rem;
}

.actionsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.optionItem {
    font-size: 12px;
}

.option {
    border-radius: 50%;
    font-size: 12px;
    margin-left: .5rem;
    padding: 2px;
    width: 30px;
    height: 30px;
    background: rgb(88, 88, 88);
    color: white;
    border-color: rgb(88, 88, 88);
}

.option::after {
    content: none;
}