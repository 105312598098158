.brandLabel {
    font-size: 14px;
    text-decoration: none;
    color: white;
    margin: auto;
}

.iconGroup {
    display: grid;
    text-align: center;
    margin-right: 1rem;
}

.iconLabel {
    text-align: center;
}

.icon {
    text-align: center;
    margin: auto;
    font-size: 1.5rem;
    margin-bottom: .3rem;
}

.userName {
    font-family: FiraSans-Medium!important;
    font-size: 14px;
    padding: 0px;
    padding-right: 15px;
    text-align: left;
}

.userRole {
    font-family: FiraSans-Light!important;
    font-size: 12px;
    padding: 0px;
    padding-right: 15px;
    text-align: left;
}

.userContainer {
    background: transparent;
    border: 0px solid transparent;
    display: flex;
}

.userContainer:hover {
    background-color: transparent;
}

.userContainer:focus {
    background-color: transparent;
}

.userContainer::after {
    margin: auto;
    margin-left: .5rem;
}

.alert {
    position: absolute!important;
    bottom: 15px;
    right: 5px;
    border-radius: 50%;
    top: auto!important;
}

.optionItem {
    font-size: 12px;
}

.option {
    background: transparent!important;
    border: 0px solid transparent!important;
    text-align: center;
}

.option::after {
    content: none;
}

.alertIcon {
    position: relative;
    text-align: center;
    margin: auto;
    font-size: 1.5rem;
    margin-bottom: 0.3rem;
}

.alertVerMas {
    font-size: 12px;
    text-align: center;
    font-family: FiraSans-SemiBold!important;
    padding: 10px;
    color: #AD0039;
}

.menuGroup {
    padding: 0px!important;
    min-width: 370px;
}

.alertItemIcon {
    color: #AD0039;
    font-size: 40px;
}

.alertItemTitle {
    font-size: 16px;
    font-family: FiraSans-SemiBold!important;
}

.alertItemDetail {
    font-size: 12px;
    font-family: FiraSans-Light!important;
    white-space: initial;
    line-height: 15px;
}

.alertItemTime {
    font-size: 14px;
    text-align: right;
}

.alertItemContainer {
    padding-top: .7rem;
    padding-bottom: .7rem;
    border-bottom: 1px solid #FFFFFF;
    background-color: #FFFFFF;
    cursor: pointer;
}

.alertItemViewed {
    padding-top: .7rem;
    padding-bottom: .7rem;
    border-bottom: 1px solid #E2F0FE;    
    background-color: #E2F0FE;
    cursor: pointer;
}

.alertItemViewed:hover {
    background-color: #D4E9FD;
    border-bottom: 1px solid #D4E9FD;
}

.alertItemContainer:hover {
    background-color: #EBEBEB;
    border-bottom: 1px solid #EBEBEB;
}

.divider {
    margin: 0px!important;
}

.rulesTitle {
    margin: auto;
    font-size: 12px;
    font-family: 'FiraSans-Medium'!important;
    margin-top: 5px;    
}

.ruleTitle {
    font-size: 12px;
    font-family: 'FiraSans-Light'!important;
}

.ruleStatus {
    font-size: 11px;
    font-family: 'FiraSans-Regular'!important;
}