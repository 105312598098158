.group {
    margin-bottom: 2rem;
}

.groupTitle {
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    margin-bottom: 1rem;
}

.subTitle {
    margin-bottom: .5rem;
}

.condition {
    font-size: 14px;
    margin-bottom: .5rem;
    padding: 10px;
}

.featureGroup {
    margin-bottom: .5rem;
}

.featureTitle {
    font-size: 18px;
}

.add {
    font-size: 12px;
}

.line {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.rangeInput {
    font-size: 12px;
}

.rangeTitle {
    text-align: center;
}

.table thead {
    font-size: 12px;
    text-align: center;
}

.table tbody {
    font-size: 10px;
    text-align: center;    
}

.newPrimary {
    font-size: 14px;
    border-radius: 10px;
    padding: 7px;
    background-color: #AD0039;
    color: white;
    font-weight: bold;
    border: 1px solid transparent;
}
.w-full{
    width: 100%;
    display: flex;
}
.container_header {
    display: flex;
    justify-content: space-between;
}
.mx-5{
    margin-left: 2%;
    margin-right: 2%;
}
.my-5{
    margin-top: 1%;
    margin-bottom: 1%;
}

.modal_history_bg{
    background-color: black;
    z-index: 20;
}

.label {
    text-align: left;
    font-size: 10px;
    font-family: FiraSans-Regular!important;
    margin: 0;
}

.input {
    padding: 7px;
    padding-top: 2px;    
    font-size: 16px;
    border-radius: 0;
    border: 0px solid transparent;
    border-bottom: 1px solid silver;
    box-shadow: 0;
    padding-left: 0;
    font-family: FiraSans-Light!important;
}