.table {
    width: 100%;
}

.table thead th {
    font-size: 10px;
    font-family: FiraSans-Regular!important;
    font-weight: normal;
    text-align: left;
    text-align: center;
}

.table tbody tr td {
    font-size: 12px;
    font-family: FiraSans-Light!important;
    text-align: center;
}

.table tbody tr:hover {
    background-color: aliceblue;
    cursor: pointer;
}

.groupLabel {
    font-family: FiraSans-SemiBold!important;
    font-size: 16px;
    color: #AD0039;
}

.noContent {
    font-size: 12px;
}

.label {
    text-align: left;
    font-size: 12px;
    font-family: FiraSans-Regular!important;
}

.input {
    padding: 7px;
    font-size: 12px;
    border-radius: 0;
    border: 0px solid transparent;
    border-bottom: 1px solid silver;
    box-shadow: 0;
    padding-left: 0;
    font-family: FiraSans-Light!important;
}

.primary {
    font-size: 12px;
    border-radius: 10px;
    padding: 7px;
    background-color: #AD0039;
    color: white;
    font-weight: bold;
    border: 0px solid transparent;
}