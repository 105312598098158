:root {
    --label-title-color: #3E3A3A;
    --label-group-title-color: #3E3A3A;
    --input-color: #3E3A3A;
}

.input {
    width: 100%;
    font-size: 14px;
    font-weight: 200;
    background: transparent;
    border: 0px solid transparent;
    color: var(--input-color);
    font-family: FiraSans-Light!important;
}

.table {
    width: 100%;
    margin: 0;
}

.table thead th {
    font-size: 12px;
    font-family: FiraSans-Regular!important;
    font-weight: 500!important;
    text-align: left;
}

.table tbody tr td {
    font-size: 12px;
    font-family: FiraSans-Light!important;
    text-align: left;
}

.iconBtn {
    font-size: 24px;
    text-align: center;
    border: 0px solid transparent;
    background: transparent;
    cursor: pointer;
    margin: auto;
}

.iconBtn2 {
    font-size: 20px;
    text-align: center;
    border: 0px solid transparent;
    background: transparent;
    cursor: pointer;
    margin: auto;
}


.label {
    text-align: left;
    font-size: 12px;
    color: var(--label-title-color);
    font-family: FiraSans-Regular!important;
}

.groupLabel {
    font-size: 16px; 
    margin-bottom: .5rem;
    color: var(--label-group-title-color);
    font-family: FiraSans-Medium!important;
}

.group {
    margin-bottom: 1.5rem;
}

.group:hover {
    background-color: #E7EAFF;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.groupLabelAction {
    font-size: 16px; 
    margin-bottom: .5rem;
    color: var(--label-group-title-color);
    font-family: FiraSans-Medium!important;
}

.groupLabelAction:hover {
    background-color: #E7EAFF;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}
