.sideBar {
    background-color: var(--secodary-bg-w-color);
    box-shadow: 0px 0px 5px 1px gray;
    border-radius: 3px 3px 3px 3px;
    padding: 1rem;
    display: block;
    align-items: center;
    justify-content: space-between;
    min-height: 200px;
}

.sideBar .button{
    color: #AD0039;
    text-align: left;
    
    font-size: 14px;
    padding: 1rem 1rem 1rem 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.sideBar .button span{
    margin-left: .5rem;
    
}

.sideBar .button:hover{
    border-left: .4rem solid #AD0039;
    background-color: #E4E1E1;
    font-weight: bold;
}

.sideBar .button.active{
    border-left: .4rem solid #AD0039;
    background-color: #E4E1E1;
    font-weight: bold;
}

.container {
    background-color: var(--secodary-bg-w-color);
    box-shadow: 0px 0px 5px 1px gray;
    border-radius: 1px 1px 1px 1px;

    display: block;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 30px 20px 30px;
}

.filterContainer {
    margin-top: 1rem;
}

.container h2 {
    color: #AD0039;
    font-weight: bold;
    text-align: left;
    font-size: 26px;
}

.primary {
    font-size: 12px;
    border-radius: 10px;
    padding: 7px;
    background-color: #AD0039;
    font-weight: bold;
    border: 0px solid transparent;
}

.label {
    text-align: left;
    font-size: 12px;
    font-family: FiraSans-Regular!important;
    margin: 0;
}

.input {
    padding: 7px;
    font-size: 12px;
    border-radius: 0;
    border: 0px solid transparent;
    border-bottom: 1px solid silver;
    box-shadow: 0;
    padding-left: 0;
    font-family: FiraSans-Light!important;
}