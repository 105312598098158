.pagination {
    margin-bottom: 0;
    text-align: right;
}

.pagination :global(.page-item) :global(.page-link) {
    color: black;
    background: #F7F7F7;
    border: 0px solid transparent;
    font-family: FiraSans-Light!important;
    font-size: 10px;
}

.pagination :global(.page-item):global(.active) :global(.page-link) {
    color: black;
    background: #C4C4C4;
    border: 0px solid transparent;
    font-family: FiraSans-Regular!important;
    font-size: 10px;
}    