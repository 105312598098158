.label {
    text-align: left;
    font-size: 10px;
    font-family: FiraSans-Regular!important;
    margin: 0;
}

.input {
    padding: 7px;
    padding-top: 2px;    
    font-size: 16px;
    border-radius: 0;
    border: 0px solid transparent;
    border-bottom: 1px solid silver;
    box-shadow: 0;
    padding-left: 0;
    font-family: FiraSans-Light!important;
}
