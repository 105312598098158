.table thead {
    font-size: 12px;
    text-align: center;
}

.table tbody {
    font-size: 10px;
    text-align: center;    
}

.next {
    background: transparent;
    color: #AD0039;
    border: 0px solid transparent;
    cursor: pointer;
    font-size: 12px;
}

.next:hover {
    font-weight: bold;
}