.digit {
    text-align: center;
    font-size: 50px;    
    margin: 0px;
    font-family: FiraSans-Regular!important;
}

.label {
    font-family: FiraSans-Light!important;
    text-align: center;
    font-size: 14px;   
}