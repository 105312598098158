.stepContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;    
    flex: 0 0 0%;
}

.step {
    width: 30px;
    height: 30px;
    font-size: 14px;
    padding: 0px;
    margin: auto;
}

.current {
    background: #AD0039;
    color: white;
    font-family: FiraSans-SemiBold!important;
    border-color: #AD0039;    
}

.passed {
    background: #AD0039;
    color: white;
    font-family: "FiraSans-Black"!important;
    font-weight: bolder;
    border-color: #AD0039;
}

.default {
    background: rgb(80, 80, 80);
    color: white;
    font-family: FiraSans-Thin!important;
    border-color: rgb(80, 80, 80);
}

.stepLabel {
    text-align: center;
    font-size: 10px;
    margin: auto;
}

.stepLabelCurrent {
    font-size: 12px;
    font-family: FiraSans-Medium!important;
    margin: auto;
    text-align: center;
}