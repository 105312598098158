:root {
    --label-title-color: #3E3A3A;
    --label-group-title-color: #3E3A3A;
    --input-color: #3E3A3A;
}

.label {
    text-align: left;
    font-size: 12px;
    color: var(--label-title-color);
    font-family: FiraSans-Regular!important;
}

.groupLabel {
    font-size: 16px; 
    margin-bottom: .5rem;
    color: var(--label-group-title-color);
    font-family: FiraSans-Medium!important;
    padding: 0;
}

.group {
    margin-bottom: 1.5rem;
}

.title {
    font-family: FiraSans-Thin!important;
    font-size: 12px;
    margin: 0!important;
}

.name {
    font-family: FiraSans-Light!important;
    font-size: 14px;
    margin: 0!important;
}

.addIcon {
    font-size: 171px;
    color: silver;
    cursor: pointer;
}

.addIconContainer {
    padding: 10px;
    border: 5px dashed silver;
    cursor: pointer;
}

.addIcon:hover {
    color: gray;
}

.addIconContainer:hover {
    color: gray;
}

.emptyData {
    padding: 0px;
    margin-bottom: 1rem;
    font-family: FiraSans-Light!important;
    font-size: 12px;
}

.unassign {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 2em;
    cursor: pointer;
    fill: gray;
    stroke-width: 1.5;
    stroke: white;
}