.group {
    margin-bottom: 2rem;
}

.groupTitle {
    font-size: 18px;
    font-weight: 600;
    width: 100%;
}

.subTitle {
    margin-bottom: .5rem;
}

.condition {
    font-size: 14px;
    margin-bottom: .5rem;
    padding: 10px;
}

.crimeGroup {
    margin-bottom: .5rem;
}

.crimeTitle {
    font-size: 18px;
    font-weight: 600;
}

.add {
    font-size: 12px;
}

.line {
    margin-top: 1rem;
    margin-bottom: 1rem;
}